import { Injectable, NgModule } from '@angular/core';
import {
  Routes, RouterModule, PreloadingStrategy, Route
} from '@angular/router';
import { LoggedInGuard } from './_core/guards/loggedin/loggedin.guard';
import { Observable, of } from 'rxjs';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preloadedModules: string[] = [];

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data?.preload) {
      this.preloadedModules.push(route.path);
      return load();
    }

    return of(null);
  }
}

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./_modules/login/login.module').then((m) => m.LoginModule),
    data: { title: 'Ingresar' }
  },
  {
    path: 'eliminar-cuenta',
    loadChildren: () => import('./_modules/eliminar-cuenta/eliminar-cuenta.module').then((m) => m.EliminarCuentaModule),
    data: { title: 'Darme de baja' }
  },
  {
    path: 'sin-conexion',
    loadChildren: () => import('./_modules/offline/offline.module').then(m => m.OfflineModule),
    data: { title: 'Sin conexión', preload: true },
  },
  {
    path: '',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./_modules/pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategy })],
  exports: [RouterModule],
  })
export class AppRoutingModule { }
