import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './core-import.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefreshTokenService } from './interceptors/refresh-token/refresh-token.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [],
  imports: [
  CommonModule,
  HttpClientModule,
  MatSnackBarModule
  ],
  exports: [],
  providers: [
  {
  provide: HTTP_INTERCEPTORS,
  useClass: RefreshTokenService,
  multi: true
  },
  ]
  })
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
