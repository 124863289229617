import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import {
  catchError, switchMap, filter, take, map
} from 'rxjs/operators';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse
} from '@angular/common/http';
import { UserService } from '../../services/user/user.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { LogoutService } from '../../authentication/logout.service';
import { ApiService } from '../../http/api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { silentErrorCodes, lookUp, getErrorCode } from '../../dictionary/error.dictionary';
import get from 'lodash/get';

@Injectable({
  providedIn: 'root'
  })
export class RefreshTokenService implements HttpInterceptor {
  public snackbarConfig: MatSnackBarConfig<any> = {
    duration: 10000,
    verticalPosition: 'top'
  };

  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public api: ApiService,
    public userService: UserService,
    public authService: AuthenticationService,
    public logoutService: LogoutService,
    public snackBar: MatSnackBar
  ) { }

  public handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authService.loadRefreshToken();
      return this.userService.refreshToken(refreshToken).pipe(
        switchMap((res: any) => {
          this.authService.setToken(res.token);
          this.api.setAuthorizationHeader();
          this.isRefreshing = false;
          this.refreshTokenSubject.next(res.token);

          const headers = this.api.getHeaders();
          const newRequest = req.clone({ headers });
          return next.handle(newRequest);
        })
      );
    }
    // Queue all the requests that are being sent while refreshing the token
    // and handle them once the refreshing is completed
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(() => {
        const headers = this.api.getHeaders();
        const newRequest = req.clone({ headers });
        return next.handle(newRequest);
      })
    );
  }

  public logOutUser(err, sessionExpired?: boolean) {
    // For (so far) unknown reasons, we can not successfully inject UserDataService in this
    // service. So we do the next best thing: broadcast an event to let UserDataService subscribe
    // to it and perform the logout.
    this.logoutService.userLogoutEvent.next(sessionExpired);
    return throwError(err);
  }

  /**
   * Shows error toast message.
   * @param err err
   */
  public presentErrorToast(err: any) {
    // Always gets first error code
    const errorCode = getErrorCode(err);
    if (!silentErrorCodes.includes(errorCode)) {
      if (errorCode) {
        this.snackBar.open(lookUp(errorCode), 'Cerrar', this.snackbarConfig);
      } else {
        // error toast for unknowns codes.
        this.snackBar.open('Ocurrió un error desconocido.', 'Cerrar', this.snackbarConfig);
      }
    }

    return throwError(err);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(
        (err: any) => {
          if (err.status === 0) {
            // No connection.
            this.snackBar.open('Error al intentar conectarse con el servidor.', 'Cerrar', this.snackbarConfig);
            return throwError(err);
          }

          if (err.status === 503 && !silentErrorCodes.includes(getErrorCode(err))) {
            // No connection to back-end process, but server is online.
            this.snackBar.open('El servidor no se encuentra disponible en este momento.', 'Cerrar', this.snackbarConfig);
            return throwError(err);
          }

          if (err.status === 401) {
            // Invalid token
            return this.handle401Error(req, next);
          }

          return this.presentErrorToast(err);
        }
      ),
      map((res: any) => {
        if (res instanceof HttpResponse && res.body?.errors) {
          if (get(res.body, 'errors[0].msg.errorCode', false) === '300') {
            this.snackBar.open('Su sesión ha caducado. Ingrese nuevamente.', 'Cerrar', this.snackbarConfig);
            return this.logOutUser(res, true);
          }
        }
        return res;
      })
    );
  }
}
