import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn
} from '@angular/router';
import { UserDataService } from '../../authentication/user-data.service';

export const LoggedInGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const userDataService = inject(UserDataService);
  const router = inject(Router);

  if (userDataService.userIsLoggedIn()) {
    return true;
  }

  if (state.url.includes('cuit')) {
    // full url without params
    const [returnUrl] = state.url.split('?');

    return router.createUrlTree(['/login'], { queryParams: { returnUrl, cuit: next.queryParams.cuit } });
  }

  return router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } });
};
